import Rollbar from 'rollbar';
import packageInfo from '../../../package.json';
import { NextApiRequest } from 'next';

const rollbarClient = new Rollbar({
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local' || false,
    payload: {
        environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
        client: {
            javascript: {
                // -- Add this into your configuration ---
                code_version: packageInfo.version,
                // ---------------------------------------

                // Optionally have Rollbar guess which frames the error was
                // thrown from when the browser does not provide line
                // and column numbers.
                guess_uncaught_frames: true
            }
        }
    }
});

export function sendToRollbarClient(errorDetails: ErrorDetails) {
    _sendToRollbar_({ instance: rollbarClient, prefix: 'FRONT' }, errorDetails);
}

// -------------------------------------------------------------- //
// --- Common utilities for Rollbar Server and Rollbar Client --- //
// -------------------------------------------------------------- //

export type ErrorDetails = {
    error: Error | string | unknown;
    prefix?: string;
    extra?: Record<string, any>;
};

type RollbarSegment = {
    instance: Rollbar;
    prefix: string;
};

// https://docs.rollbar.com/docs/rollbarjs-configuration-reference#rollbarlog
export function _sendToRollbar_(segment: RollbarSegment, errorDetails: ErrorDetails) {
    let error =
        errorDetails.error instanceof Error
            ? errorDetails.error
            : new Error(`${errorDetails.error}`);

    const completePrefix = `[${segment.prefix}] ${errorDetails.prefix ?? ''}`;
    const msg = `${error.name} - ${error.message}`;

    // Change the error name to print it correctly in rollbar item list
    error.name = completePrefix.trim();

    segment.instance.error(msg, error, translateBody(errorDetails.extra));
}

function translateBody(body?: Record<string, any>): Record<string, any> | undefined {
    let toSend: Record<string, any> = {};

    if (!body) {
        return undefined;
    } else if (body instanceof Error) {
        toSend = translateError(body);
    } else {
        const objectKeys = Object.keys(body);
        for (const key of objectKeys) {
            const newKey = rollbarReservedKeys.includes(key) ? `_${key}_` : key;
            if (body[key] instanceof Error) {
                toSend[newKey] = translateError(body[key]);
            } else {
                toSend[newKey] = body[key];
            }
        }
    }
    return toSend;
}

function translateError(error: Error) {
    return {
        name: error.name,
        message: error.message,
        stack: error.stack
    };
}

// Export it because there is not an easy way to know if an object is an instance of NextApiRequest
export function translateNextRequest(req: NextApiRequest) {
    return {
        method: req.method,
        url: req.url,
        query: req.query,
        body: req.body
            ? typeof req.body === 'object'
                ? JSON.stringify(req.body)
                : req.body
            : undefined,
        headers: {
            ...req.headers,
            authorization: req.headers.authorization ? 'REDACTED' : undefined,
            cookie: req.headers.cookie ? 'REDACTED' : undefined
        },
        ip: req.socket.remoteAddress,
        host: req.headers.host,
        referer: req.headers.referer
    };
}

/*
    Rollbar reserved keys that we can NOT use in custom data.
    If present, the custom data will be ignored and/or overwrite the original data.

    There is no official documentation about that issue. It was found by testing.
    The list is taken from https://docs.rollbar.com/reference/create-item and by checking items in the Rollbar dashboard.
*/
const rollbarReservedKeys = [
    'environment',
    'body',
    'level',
    'timestamp',
    'code_version',
    'platform',
    'language',
    'framework',
    'context',
    'request',
    'person',
    'server',
    'client',
    'custom',
    'fingerprint',
    'title',
    'uuid',
    'notifier',
    'retentionDays',
    'metadata',
    'endpoint',
    'response'
];
