const DELFI_FAB_SCRIPT_ID = 'delfi-fab-script';
const DELFI_FAB_CONTAINER_ID = 'delfi-fab-container';

interface FABChatService {
    createFABChat: (supportClientId: string, iframeWidth: number, iframeHeight: number) => void;
    removeFABChat: () => void;
}

export class FABChatServiceImp implements FABChatService {
    createFABChat(supportClientId: string, iframeWidth: number, iframeHeight: number) {
        const script = document.createElement('script');
        script.src = `${process.env.BASE_URL}/api/scripts/fab-button?client=${supportClientId}&iframeWidth=${iframeWidth}&iframeHeight=${iframeHeight}`;
        script.id = DELFI_FAB_SCRIPT_ID;

        document.body.appendChild(script);
    }

    removeFABChat() {
        const script = document.getElementById(DELFI_FAB_SCRIPT_ID);
        const container = document.getElementById(DELFI_FAB_CONTAINER_ID);

        if (script) script.remove();
        if (container) container.remove();
    }
}
