import { Report } from '@prisma/client';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';

export interface ReportService {
    getReport(): Promise<BaseResponse<Report>>;
    makeReport(): Promise<BaseResponse<Report>>;
    updateTemplate(template: string): Promise<BaseResponse<Report>>;
}

export class ReportServiceImp implements ReportService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        this.httpRequest = httpRequest;
    }

    async getReport(): Promise<BaseResponse<Report>> {
        return await this.httpRequest.get({
            url: generateUrl('api/report')
        });
    }

    async makeReport(): Promise<BaseResponse<Report>> {
        return await this.httpRequest.post({
            url: generateUrl('api/report')
        });
    }

    async updateTemplate(template: string): Promise<BaseResponse<Report>> {
        return await this.httpRequest.put({
            url: generateUrl('api/report'),
            body: { template }
        });
    }
}
