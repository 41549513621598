import React from 'react';

const LimitationBanner = ({ text }: { text: string }) => {
    return (
        <section className="bg-primary flex items-center relative  items-center justify-center h-10">
            <p className="text-md text-white font-medium">{text}</p>
        </section>
    );
};

export default LimitationBanner;
