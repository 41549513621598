import Image from 'next/image';

import modalStyles from '@/app/styles/modal.module.css';

interface Text {
    text: string;
    size?: string;
    weight?: string;
    color?: string;
}

interface ActionButton {
    message: Text;
    action: () => void;
    disabled?: boolean;
}

interface Icon {
    name: string;
    width: number;
    height: number;
}

interface ModalProps {
    title?: Text;
    messages: string[];
    actions?: ActionButton[];
    useIcon?: boolean;
    icon?: Icon;
    maxWidth?: string;
    onClose?: () => void;
    children?: React.ReactNode;
}

const defaultAction = [{ message: { text: 'Aceptar' }, action: () => {} }];
const defaultIcon = { name: 'warning', width: 64, height: 64 };

const Modal = ({
    title,
    messages,
    actions = defaultAction,
    useIcon = true,
    icon = defaultIcon,
    maxWidth,
    onClose,
    children
}: ModalProps) => {
    const styles = {
        '--modal-max-width': maxWidth,
        '--title-size': title?.size,
        '--title-weight': title?.weight,
        '--title-color': title?.color
    } as any;

    return (
        <div style={styles} className="absolute z-[60] inset-0 overflow-y-auto">
            <div className="fixed block items-end justify-center min-h-screen">
                <div
                    className="fixed inset-0 bg-[#00000033] transition-opacity"
                    onClick={onClose}
                />

                <div
                    className={`${modalStyles.container} fixed inline-block w-[90%] sm:w-auto bg-white rounded-[13px] top-1/2 -translate-y-[60%] left-1/2 transform -translate-x-1/2 px-3 pt-5 pb-4 text-left overflow-hidden shadow-modal transition-all sm:p-6`}
                >
                    <div className="flex flex-col items-center text-center">
                        {useIcon && (
                            <Image
                                height={icon.height}
                                width={icon.width}
                                src={`/icons/${icon.name}.svg`}
                                alt="modal-icon"
                            />
                        )}
                        {title && (
                            <h1 className={`${modalStyles.title} mt-3 mb-1`}>{title.text}</h1>
                        )}

                        {messages?.map((message: string, index: number) => (
                            <p key={`message-${index}`} className="text-sm text-gray-70 mb-2">
                                {message}
                            </p>
                        ))}

                        {children}

                        <div className="flex justify-evenly flex-nowrap mt-4 gap-x-6">
                            {actions?.map((button: ActionButton, index: number) => (
                                <button
                                    key={`action-${index}`}
                                    className="w-40 disabled:cursor-not-allowed"
                                    onClick={button.action}
                                    disabled={button.disabled}
                                >
                                    <span
                                        style={{
                                            color: `var(--${button.message.color}, black)`,
                                            fontWeight: `${button.message.weight || 400}`,
                                            fontSize: `${button.message.size || '14px'}`
                                        }}
                                    >
                                        {button.message.text}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
