import { ClientPlan } from '@prisma/client';
import { BaseService } from './base-service';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';

export interface ClientPlanService {
    getAllPlans(): Promise<BaseResponse<ClientPlan[]>>;
}

export class ClientPlanServiceImp extends BaseService implements ClientPlanService {
    constructor(private httpRequest: HttpRequest) {
        super('ClientPlan-Service');
    }

    getAllPlans(): Promise<BaseResponse<ClientPlan[]>> {
        const request = () =>
            this.httpRequest.get<ClientPlan[]>({
                url: generateUrl(`api/admin/plan`)
            });

        return this.tryRequest<ClientPlan[]>(request);
    }
}
