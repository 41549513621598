import { Message } from '@prisma/client';
import { BaseService } from './base-service';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';

export interface MessageService {
    sendNewAgentMessage(message: string, chatId: string): Promise<BaseResponse<Message>>;
}

export class MessageServiceImp extends BaseService implements MessageService {
    constructor(private httpRequest: HttpRequest) {
        super('Message-Service');
    }

    async sendNewAgentMessage(message: string, chatId: string): Promise<BaseResponse<Message>> {
        const request = () =>
            this.httpRequest.post<Message>({
                url: generateUrl(`api/chat/${chatId}/agent/message`),
                body: { message }
            });

        return this.tryRequest<Message>(request);
    }
}
