import { useState, useEffect } from 'react';

const VersionInfo = () => {
    const [version, setVersion] = useState('unknown');
    const [build, setBuild] = useState('unknown');

    useEffect(() => {
        const fetchVersion = () => {
            try {
                const packageInfo = require('../../../../package.json');
                setVersion(packageInfo.version);
            } catch (error) {
                console.error('No se pudo obtener la versión del producto', error);
            }
        };

        fetchVersion();

        setBuild(process.env.NEXT_PUBLIC_BITBUCKET_BUILD_NUMBER || 'local');
    }, []);

    return (
        <div className="text-sm text-left text-black/75 font-thin leading-normal p-8 pl-12 absolute bottom-0 left-0 w-full">
            <p>Version: {version}</p>
            <p>Build: {build}</p>
        </div>
    );
};

export default VersionInfo;
