import { ClientPlanWrapper } from '@/models/client-plan-wrapper';
import { Client, ClientPlan, User } from '@prisma/client';
import { createContext, useContext } from 'react';

interface UserContextProps {
    user: User;
    client?: Client;
    clientPlan: ClientPlanWrapper;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserContextProviderProps {
    user: User;
    client?: Client;
    clientPlan?: ClientPlan;
    children: React.ReactNode;
}

export const UserContextProvider = ({
    children,
    user,
    client,
    clientPlan
}: UserContextProviderProps) => {
    return (
        <UserContext.Provider
            value={{
                user,
                client,
                clientPlan: clientPlan
                    ? new ClientPlanWrapper(clientPlan)
                    : ClientPlanWrapper.getAdminPlan()
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserContextProvider');
    }
    return context;
};

export default useUser;
