import { useEffect } from 'react';
import useUser from '@/app/contexts/userContext';
import { FABChatServiceImp } from '@/app/services/fab-chat-service';

export default function FABChat() {
    const { clientPlan } = useUser();
    const fabChatService = new FABChatServiceImp();

    const supportClientId = clientPlan.isFree()
        ? process.env.NEXT_PUBLIC_SUPPORT_CLIENT_ID_FREE
        : process.env.NEXT_PUBLIC_SUPPORT_CLIENT_ID;

    // TODO: Remove this when the fab-button script is updated.
    useEffect(() => {
        if (supportClientId) fabChatService.createFABChat(supportClientId, 400, 600);

        return () => {
            fabChatService.removeFABChat();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
