import { cn } from '@/app/utils/cn';

export enum SpinnerColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

interface SpinnerProps {
    color?: SpinnerColor;
    size?: 'small' | 'medium' | 'large';
    className?: string;
}

export function Spinner({
    color = SpinnerColor.PRIMARY,
    size = 'medium',
    className
}: SpinnerProps) {
    const spinnerSize = size === 'small' ? 'h-6 w-6' : size === 'medium' ? 'h-8 w-8' : 'h-12 w-12';

    return (
        <div
            className={cn(
                `inline-block ${spinnerSize} animate-spin rounded-full border-4 border-solid ${
                    color === 'primary' ? 'border-primary' : 'border-secondary'
                } aspect-square border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`,
                className
            )}
        ></div>
    );
}
