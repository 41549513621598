import { MessageCount } from '@/services/model/analyticsService';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { PaginatedData } from '@/types/paginatedData';
import { BaseService } from './base-service';

export interface AnalyticsService {
    countMessages(criteria: {
        page: number;
        pageSize: number;
        option: string;
        clientId?: string;
        from?: number;
        to?: number;
    }): Promise<BaseResponse<PaginatedData<MessageCount>>>;
}

export class AnalyticsServiceImp extends BaseService implements AnalyticsService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Analytics-Service');
        this.httpRequest = httpRequest;
    }

    async countMessages(criteria: {
        page: number;
        pageSize: number;
        option: string;
        clientId?: string;
        from?: number;
        to?: number;
    }): Promise<BaseResponse<PaginatedData<MessageCount>>> {
        const request = () =>
            this.httpRequest.get<PaginatedData<MessageCount>>({
                url: generateUrl('api/analytics', criteria)
            });

        return this.tryRequest<PaginatedData<MessageCount>>(request);
    }
}
