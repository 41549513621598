import { fetchEventSource } from '@microsoft/fetch-event-source';

import { NavigationRouter } from '../utils/navigation-router';

export interface BaseRequest {
    url: string;
    body?: Object;
    headers?: Object;
}

export interface EventSourceRequest {
    url: string;
    method: string;
    body?: any;
    onerror?: (err: any) => number | void | null | undefined;
    onmessage?: (data: any, ctrl: AbortController) => void;
}

//if you have a better idea to unify the 401 redirection, you are welcome.
export interface BaseResponse<T> {
    body: T;
    error?: any;
    errorCode?: any;
    sessionExpired: boolean;
}

export interface HttpRequest {
    get<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    post<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    put<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    patch<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;
    delete<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>>;

    fetchEventSource(EventSourceRequest: EventSourceRequest): Promise<void>;
}

export class FetchHttpRequest implements HttpRequest {
    navigationRouter: NavigationRouter | undefined;

    //pass the navigationRouter to enable session verifying
    constructor(navigationRouter?: NavigationRouter) {
        this.navigationRouter = navigationRouter;
    }

    async get<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'GET',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async post<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'POST',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async put<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'PUT',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async patch<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'PATCH',
            baseRequest.body,
            baseRequest.headers
        );
    }

    async delete<T>(baseRequest: BaseRequest): Promise<BaseResponse<T>> {
        return await this.basicRequest<T>(
            baseRequest.url,
            'DELETE',
            baseRequest.body,
            baseRequest.headers
        );
    }

    private async basicRequest<T>(
        url: string,
        method: string,
        body?: Object,
        headers?: Object
    ): Promise<BaseResponse<T>> {
        return this.catchFailedToFetch(async () => {
            let reqHeaders: HeadersInit = Object.entries(
                headers ? { ...this.defaultHeaders(), ...headers } : this.defaultHeaders()
            );

            // If the body is a FormData object, we don't want to set the Content-Type header
            // The browser will set it automatically with the correct boundary
            const isFormData = body instanceof FormData;
            const reqInfo = isFormData
                ? { body, headers: reqHeaders.filter(([key]) => key !== 'Content-Type') }
                : { body: JSON.stringify(body), headers: reqHeaders };

            const response = await fetch(url, { method, ...reqInfo });

            if (!(response.status >= 200 && response.status < 300)) {
                const sessionExpired = response.status == 401;
                const result = await response.json();
                if (sessionExpired) {
                    if (this.navigationRouter) {
                        this.navigationRouter.toLogin();
                    }
                }

                return {
                    body: {} as T,
                    error: result?.message || 'Ha ocurrido un error.',
                    errorCode: result?.code,
                    sessionExpired
                };
            }

            const resBody = response.status !== 204 ? await response.json() : undefined;
            return {
                body: resBody,
                sessionExpired: false
            };
        });
    }

    async fetchEventSource(request: EventSourceRequest): Promise<void> {
        const req = async () => {
            const ctrl = new AbortController();

            await fetchEventSource(request.url, {
                method: request.method,
                openWhenHidden: true,
                body: request.body,
                signal: ctrl.signal,
                onerror: (error) => {
                    console.error(error);
                    request.onerror?.(error);
                    ctrl.abort();
                    throw error;
                },
                onmessage: (ev) => request.onmessage?.(ev, ctrl)
            });
        };

        await this.catchFailedToFetch(req);
    }

    /**
     *
     * Avoid reporting errors to Rollbar when the error is due to a network problems or
     * when the request is aborted by the user (e.g. when the user navigates to another page).
     *
     * Usually thrown when the iframe of the webchat is removed from the DOM before the fetch
     * request is completed.
     *
     * @param req The request to be made
     * @returns The response of the request
     */
    private async catchFailedToFetch<T>(
        req: () => Promise<BaseResponse<T>>
    ): Promise<BaseResponse<T>>;
    private async catchFailedToFetch(req: () => Promise<void>): Promise<void>;
    private async catchFailedToFetch<T>(
        req: () => Promise<BaseResponse<T> | void>
    ): Promise<BaseResponse<T> | void> {
        return req().catch((error) => {
            const isFailedToFetchError =
                error instanceof TypeError &&
                ['Failed to fetch', 'Load failed'].includes(error.message);

            if (!isFailedToFetchError) throw error;
            return { body: {} as T, error, sessionExpired: false };
        });
    }

    defaultHeaders() {
        return {
            'Content-Type': 'application/json'
        };
    }
}
