import { usePathname } from 'next/navigation';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { dynamicModals } from '../../components/organisms/modal';
import {
    ModalManagerValues,
    ModalStatus,
    CloseModalFn,
    IsOpenFn,
    useModalManagerFn,
    ShowModalFn
} from './types';

const ModalManagerContext = createContext<ModalManagerValues | undefined>(undefined);

export const ModalManagerProvider = ({ children }: { children: React.ReactNode }) => {
    const [openModals, setOpenModals] = useState<ModalStatus[]>([]);
    const pathname = usePathname();

    const showModal: ShowModalFn = (ns, key, props, options) => {
        setOpenModals((prev) =>
            options?.multiple ? [...prev, { ns, props, key }] : [{ key, props, ns }]
        );
    };

    const closeModal: CloseModalFn = (ns, key) => {
        if (ns === undefined) {
            setOpenModals([]);
            return;
        }
        setOpenModals((prev) =>
            prev.filter((statusModal) => !(statusModal.ns === ns && statusModal.key === key))
        );
    };

    const isOpen: IsOpenFn = (ns, key) =>
        key
            ? openModals.findIndex((modal) => modal.key === key && modal.ns === ns) !== -1
            : openModals.length > 0;

    const renderModals = useMemo(
        () =>
            openModals.map((modal) => {
                const Modal = (dynamicModals[modal.ns] as any)[
                    modal.key
                ] as React.FC<ModalImpProps>;
                return (
                    <Modal
                        key={modal.key}
                        {...modal.props}
                        onClose={() => {
                            modal.props?.onClose?.();
                            closeModal(modal.ns, modal.key);
                        }}
                        open
                    />
                );
            }),
        [openModals]
    );

    useEffect(() => {
        closeModal();
    }, [pathname]);

    return (
        <ModalManagerContext.Provider
            value={{
                showModal,
                closeModal,
                isOpen
            }}
        >
            {renderModals}
            {children}
        </ModalManagerContext.Provider>
    );
};

export const useModalManager: useModalManagerFn = (namespace) => {
    const context = useContext(ModalManagerContext);

    if (context === undefined) throw Error('Out of context: useModalManagerProvider');

    return {
        showModal: (key, props, options) => context.showModal(namespace, key, props, options),
        closeModal: (key) => context.closeModal(namespace, key),
        isOpen: (key) => context.isOpen(namespace, key)
    };
};

export interface ModalImpProps {
    icon?: string;
    title: string;
    messages: string[];
    onClose?: () => void;
}
