import Image from 'next/image';
import { useEffect, useState } from 'react';
import { cn } from '@/app/utils/cn';

import Link from 'next/link';
import useUser from '@/app/contexts/userContext';
import Modal from './modal/modal';
import { ArrowLeft } from 'lucide-react';
import BasicButton, { BasicButtonType, ButtonShape } from '../molecules/basic-button';
import LimitationBanner from '../molecules/limitation-banner';

interface HeaderProps {
    onSidebarToggle: () => void;
}

const Header = ({ onSidebarToggle }: HeaderProps) => {
    const defaultLogo = '/images/delfi-logo.png';

    const { client, clientPlan } = useUser();

    useEffect(() => {
        if (localStorage.getItem('showCTA') === 'true') {
            clientPlan.isFree() && setShowCtaModal(true);
            localStorage.removeItem('showCTA');
        }
    }, []);

    const [showCtaModal, setShowCtaModal] = useState<boolean>(false);

    const CtaModal = () => (
        <Modal
            title={{
                text: 'Workshop IA para E-Commerce',
                weight: '600',
                size: '24px',
                color: 'black'
            }}
            icon={{ name: 'chatbot', width: 64, height: 64 }}
            messages={[]}
            actions={[]}
        >
            <div className="text-base text-left leading-7 mt-2">
                Sabemos que una herramienta de Inteligencia Artificial puede ser difícil de entender
                y compleja de configurar para tu tienda E-Commerce. Lo entendemos a la perfección y
                por eso es que diseñamos un Workshop específico para ayudarte en el proceso. <br />
                En el Workshop te enseñaremos con capacitaciones online todo sobre Inteligencia
                Artificial, te ayudaremos de manera personalizada por todo un mes para que logres
                configurar las herramientas y te daremos acceso a nuestros mejores expertos para que
                les hagas todas las consultas que tengas.
            </div>
            <div className="flex flex-row w-full justify-between items-center gap-4 mt-6">
                <button
                    className="text-red-400 font-[500] flex flex-row gap-2 items-center"
                    onClick={() => setShowCtaModal(false)}
                >
                    <ArrowLeft size={24} color="#f87171" />
                    Omitir
                </button>
                <a
                    className="bg-blue-700 text-white p-4 rounded-[8px] font-[600] flex flex-row gap-3 items-center"
                    href="https://delfi.chat/workshop-ecommerce/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    ¡QUIERO SABER MÁS DEL WORKSHOP!
                </a>
            </div>
        </Modal>
    );

    return (
        <header
            className={cn(
                `${clientPlan.isFree() ? 'h-30' : 'h-20'}`,
                'bg-white flex flex-col  fixed inset-x-0 z-20 shadow-10'
            )}
        >
            {clientPlan.isFree() && (
                <LimitationBanner text="Versión gratuita con Inteligencia básica limitada" />
            )}
            <section className="flex flex-row w-full items-center">
                <button
                    className="text-black mx-9 p-1 rounded-full hover:bg-slate-100 active:bg-slate-200"
                    onClick={onSidebarToggle}
                >
                    <Image height={24} width={24} src="/icons/menu.svg" alt="menu" />
                </button>

                <div className="w-40 h-20 relative">
                    <Image
                        className="py-2"
                        src={client?.logoUrl || defaultLogo}
                        alt="client logo"
                        fill
                        style={{ objectFit: 'scale-down' }}
                    />
                </div>
                {!clientPlan.isFree() && (
                    <Link
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdCROdJFkg9xSYrB9fYOlOJDR8FQZU-JBQRkIckRXeAi5d5Yw/viewform"
                        target="_blank"
                        className="flex items-center bg-secondary hover:cursor-pointer py-[5px] px-[15px] sm:py-[10px] sm:px-[30px] ml-auto mr-[45px] rounded-button"
                    >
                        <Image
                            height={24}
                            width={24}
                            src="/icons/report.svg"
                            alt="menu"
                            className="mr-[4px]"
                        />
                        <p className="text-primary text-sm sm:text-[16px]">Enviar sugerencias</p>
                    </Link>
                )}

                {clientPlan.isFree() && (
                    <BasicButton
                        styleType={BasicButtonType.GREEN}
                        shape={ButtonShape.PILL}
                        text="Potencia tu E-Commerce"
                        className="ml-auto mr-[45px] py-[21px] px-[32px]"
                        onClick={() => setShowCtaModal(true)}
                    />
                )}

                {showCtaModal && <CtaModal />}
            </section>
        </header>
    );
};

export default Header;
