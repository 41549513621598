import { UserWithClient } from '@/types/userWithClient';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { User } from '@prisma/client';
import { RegisterFormData } from '../pages/auth/register/utils/register-form-data-manager';

export interface LoginResponse {
    accessToken: string;
    accessTokenExpiry: string;
    user: UserWithClient;
}

export interface AuthService {
    login(email: string, password: string): Promise<BaseResponse<LoginResponse>>;
    register(userInfo: RegisterFormData): Promise<BaseResponse<LoginResponse>>;
    activate(token: string): Promise<BaseResponse<User>>;
    sendActivationMail(email: string, password?: string): Promise<BaseResponse<User>>;
    sendPasswordResetMail(email: string): Promise<BaseResponse<User>>;
    resetPassword(token: string, password: string): Promise<BaseResponse<User>>;
    getRemainingFreeAcounts(): Promise<BaseResponse<number>>;
}

export class AuthServiceImp implements AuthService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        this.httpRequest = httpRequest;
    }

    async login(email: string, password: string): Promise<BaseResponse<LoginResponse>> {
        return await this.httpRequest.post({
            url: generateUrl('api/auth/login'),
            body: { email, password }
        });
    }

    async register(userInfo: RegisterFormData): Promise<BaseResponse<LoginResponse>> {
        return await this.httpRequest.post({
            url: generateUrl('api/auth/register'),
            body: {
                ...userInfo,
                email: userInfo.email.value,
                password: userInfo.password.value,
                phoneNumber: userInfo.phoneNumber.value.fullNumber()
            }
        });
    }

    async activate(token: string): Promise<BaseResponse<User>> {
        return await this.httpRequest.patch({
            url: generateUrl(`api/auth/activate/${token}`)
        });
    }

    async sendActivationMail(email: string, password?: string): Promise<BaseResponse<User>> {
        return await this.httpRequest.post({
            url: generateUrl('api/auth/activate'),
            body: { email, password }
        });
    }

    async resetPassword(token: string, password: string): Promise<BaseResponse<User>> {
        return await this.httpRequest.patch({
            url: generateUrl(`api/auth/password-reset/${token}`),
            body: { password }
        });
    }

    async sendPasswordResetMail(email: string): Promise<BaseResponse<User>> {
        return await this.httpRequest.post({
            url: generateUrl('api/auth/password-reset'),
            body: { email }
        });
    }

    async getRemainingFreeAcounts(): Promise<BaseResponse<number>> {
        return await this.httpRequest.get({
            url: generateUrl('api/accounts/free/remaining')
        });
    }
}
