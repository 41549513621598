import React, { useEffect, useState } from 'react';

import { useGlobalContext } from '@/app/contexts/provider';
import { expireCookie } from '@/app/utils/cookie-manager';
import { Routes } from '@/enums/routes.enum';

import VersionInfo from '../molecules/version-info';
import SidebarItem from '../molecules/sidebar-item';
import useUser from '@/app/contexts/userContext';
import { useModalManager } from '@/app/contexts/modalManagerContext';

interface SidebarProps {
    isOpen: boolean;
}

export enum Name {
    CHATS = 'Chats',
    CLIENTS = 'Clientes',
    ANALYTICS = 'Analytics',
    DASHBOARD = 'Configuración',
    TUTORIAL = 'Tutorial',
    INSIGHTS = 'Insights',
    USAGE = 'Usage',
    CLOSE_SESSION = 'Cerrar sesión'
}

type SidebarOption = {
    name: Name;
    icon: string;
    availableFor: string[];
    onClick: () => void;
};

//To handle the differents icon states in the selection, add two files named: 'icon' and 'icon_not_selected'. In the icon parameter from SidebarItem, you only need to set 'icon' as value.
const Sidebar = ({ isOpen }: SidebarProps) => {
    const { navigationRouter } = useGlobalContext();
    const { clientPlan } = useUser();
    const { showModal } = useModalManager('commons');

    const [selectedItem, setSelectedItem] = useState<Name | undefined>();

    const getSelectedItemFromRoute = () => {
        switch (navigationRouter.getPath()) {
            case Routes.CHATS:
                return Name.CHATS;
            case Routes.CLIENTS:
                return Name.CLIENTS;
            case Routes.USAGE:
                return Name.USAGE;
            case Routes.DASHBOARD:
                return Name.DASHBOARD;
            case Routes.TUTORIAL:
                return Name.TUTORIAL;
            case Routes.INSIGHTS:
                return Name.INSIGHTS;
            case Routes.ANALYTICS:
                return Name.ANALYTICS;
        }
    };

    useEffect(() => {
        setSelectedItem(getSelectedItemFromRoute());
    }, [getSelectedItemFromRoute()]);

    const onItemClick = (item: Name, action: () => void): (() => void) => {
        return () => {
            setSelectedItem(item);
            action();
        };
    };

    const handleCloseSession = () => {
        const closeSession = () => {
            expireCookie('Authorization');
            navigationRouter.to(Routes.LOGIN);
        };

        showModal('confirmation', {
            title: 'Estás por cerrar sesión',
            messages: ['¿Deseas continuar?'],
            icon: 'log-out-alert',
            onClose: () => setSelectedItem(getSelectedItemFromRoute),
            onConfirm: closeSession,
            onConfirmText: 'Sí, quiero salir'
        });
    };

    const sidebarOptions: SidebarOption[] = [
        {
            name: Name.TUTORIAL,
            icon: 'play-outline',
            availableFor: [],
            onClick: () => navigationRouter.to(Routes.TUTORIAL)
        },
        {
            name: Name.DASHBOARD,
            icon: 'cog-outline',
            availableFor: ['FREE', '360', 'INITIAL'],
            onClick: () => {
                navigationRouter.to(Routes.DASHBOARD);
            }
        },
        {
            name: Name.CHATS,
            icon: 'chat',
            availableFor: ['FREE', '360', 'INITIAL', 'Admin'],
            onClick: () => navigationRouter.to(Routes.CHATS)
        },
        {
            name: Name.CLIENTS,
            icon: 'clients',
            availableFor: ['Admin'],
            onClick: () => navigationRouter.to(Routes.CLIENTS)
        },
        {
            name: Name.INSIGHTS,
            icon: 'insights',
            availableFor: ['Admin', 'FREE', '360', 'INITIAL'],
            onClick: () => navigationRouter.to(Routes.INSIGHTS)
        },
        {
            name: Name.ANALYTICS,
            icon: 'analytics',
            availableFor: ['FREE', '360', 'INITIAL'],
            onClick: () => navigationRouter.to(Routes.ANALYTICS)
        },
        {
            name: Name.USAGE,
            icon: 'analytics',
            availableFor: ['Admin'],
            onClick: () => navigationRouter.to(Routes.USAGE)
        },
        {
            name: Name.CLOSE_SESSION,
            icon: 'logout',
            availableFor: ['FREE', '360', 'INITIAL', 'Admin'],
            onClick: handleCloseSession
        }
    ];

    const isItemSelected = (item: Name): boolean => {
        return selectedItem === item;
    };

    return (
        <div
            className={`fixed z-10 shadow-10 inset-y-0 left-0 bg-white w-80 transform ${
                isOpen ? 'translate-x-0' : '-translate-x-full'
            } transition-transform ease-in-out duration-300`}
        >
            <nav className={`${clientPlan.isFree() ? 'mt-40' : 'mt-28'}`}>
                {sidebarOptions.map(
                    (item) =>
                        item.availableFor.includes(clientPlan.clientPlan.name) && (
                            <SidebarItem
                                key={item.name}
                                name={item.name}
                                icon={item.icon}
                                isSelected={isItemSelected(item.name)}
                                onClick={onItemClick(item.name, item.onClick)}
                            />
                        )
                )}
            </nav>

            <VersionInfo />
        </div>
    );
};

export default Sidebar;
