import Image from 'next/image';
import { Spinner, SpinnerColor } from '../atoms/spinner';
import { cn } from '@/app/utils/cn';

export enum BasicButtonType {
    PRIMARY = 'primary',
    OUTLINE = 'outline',
    GREEN = 'green',
    RED = 'red',
    GRAY_OUTLINE = 'gray',
    BLACK = 'black'
}

export enum ButtonShape {
    SQUARE = 'rounded-[6px]',
    ROUNDED = 'rounded-button',
    PILL = 'rounded-[999px]'
}

export interface BasicButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    styleType?: BasicButtonType;
    shape?: ButtonShape;
    text?: string;
    loading?: boolean;
    enabled?: boolean;
    className?: string;
    icon?: string;
    customColor?: string;
    textClassName?: string;
}

interface BasicButtonProperties {
    getSpinnerColor(): SpinnerColor;
    getTextColor(): string;
    getButtonColor(): string;
}

//TODO, move this to another file. A basic-button folder should be created.
class ButtonStyleManager implements BasicButtonProperties {
    private buttonProperties: BasicButtonProperties;

    constructor(type: BasicButtonType) {
        switch (type) {
            case BasicButtonType.GREEN:
                this.buttonProperties = new GreenButtonProperties();
                break;
            case BasicButtonType.RED:
                this.buttonProperties = new RedButtonProperties();
                break;
            case BasicButtonType.BLACK:
                this.buttonProperties = new BlackButtonProperties();
                break;
            case BasicButtonType.PRIMARY:
                this.buttonProperties = new PrimaryButtonProperties();
                break;
            case BasicButtonType.OUTLINE:
                this.buttonProperties = new OutlineButtonProperties();
                break;
            case BasicButtonType.GRAY_OUTLINE:
                this.buttonProperties = new OutlineGrayButtonProperties();
                break;
            default:
                this.buttonProperties = new OutlineButtonProperties();
                break;
        }
    }

    getSpinnerColor(): SpinnerColor {
        return this.buttonProperties.getSpinnerColor();
    }
    getTextColor(): string {
        return this.buttonProperties.getTextColor();
    }
    getButtonColor(): string {
        return this.buttonProperties.getButtonColor();
    }
}

class PrimaryButtonProperties implements BasicButtonProperties {
    getSpinnerColor(): SpinnerColor {
        return SpinnerColor.SECONDARY;
    }
    getTextColor(): string {
        return 'text-white';
    }
    getButtonColor(): string {
        return 'bg-primary hover:bg-blue-600 disabled:hover:bg-primary';
    }
}

class OutlineButtonProperties implements BasicButtonProperties {
    getSpinnerColor(): SpinnerColor {
        return SpinnerColor.PRIMARY;
    }
    getTextColor(): string {
        return 'text-primary';
    }
    getButtonColor(): string {
        return 'bg-white hover:bg-gray-50 disabled:hover:bg-white border border-primary';
    }
}

class OutlineGrayButtonProperties implements BasicButtonProperties {
    getSpinnerColor(): SpinnerColor {
        return SpinnerColor.PRIMARY;
    }
    getTextColor(): string {
        return 'text-[#2B2B2B] text-xs md:text-base font-normal';
    }
    getButtonColor(): string {
        return 'bg-white hover:bg-gray-50 disabled:hover:bg-white border border-[#D6D6D6] ';
    }
}

class GreenButtonProperties implements BasicButtonProperties {
    getSpinnerColor(): SpinnerColor {
        return SpinnerColor.SECONDARY;
    }
    getTextColor(): string {
        return 'text-white';
    }
    getButtonColor(): string {
        return 'bg-green hover:bg-green-dark border border-green-dark';
    }
}

class RedButtonProperties implements BasicButtonProperties {
    getSpinnerColor(): SpinnerColor {
        return SpinnerColor.SECONDARY;
    }
    getTextColor(): string {
        return 'text-white';
    }
    getButtonColor(): string {
        return 'bg-red-600 hover:bg-red-700 border border-red-700';
    }
}

class BlackButtonProperties implements BasicButtonProperties {
    getSpinnerColor(): SpinnerColor {
        return SpinnerColor.SECONDARY;
    }
    getTextColor(): string {
        return 'text-white';
    }
    getButtonColor(): string {
        return 'bg-black hover:bg-gray-900 border';
    }
}

const BasicButton = ({
    styleType = BasicButtonType.PRIMARY,
    shape = ButtonShape.ROUNDED,
    text,
    loading,
    className = '',
    enabled = true,
    icon,
    customColor,
    textClassName,
    ...rest
}: BasicButtonProps) => {
    const styleManager = new ButtonStyleManager(styleType);

    //TODO, separate button color from the BasicButton, doing a customizable styleManager
    function getBgColor(): string {
        return customColor ? customColor : styleManager.getButtonColor();
    }

    return (
        <button
            className={cn(
                'h-[40px] px-[14px] flex items-center justify-center duration-100 min-w-[200px] disabled:cursor-not-allowed',
                enabled ? getBgColor() : 'bg-[#B2B2B2]',
                shape,
                className
            )}
            disabled={loading || !enabled}
            {...rest}
        >
            {loading ? (
                <Spinner color={styleManager.getSpinnerColor()} size="small" />
            ) : (
                <div className="flex">
                    <span
                        className={cn('font-semibold', styleManager.getTextColor(), textClassName)}
                    >
                        {text || ''}
                    </span>

                    {icon && (
                        <Image height={20} width={20} src={icon} alt="menu" className="ml-[5px]" />
                    )}
                </div>
            )}
        </button>
    );
};

export default BasicButton;
