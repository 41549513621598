import { Prisma, ModelVersion, ClientPlan, $Enums } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { BaseService } from './base-service';
import { ContactCenterConfiguration } from '../pages/clients/client-form/utils/client-form-data-manager';
import { CTAConfiguration } from '../pages/clients/client-form/utils/client-form-data-manager';

type chatConfigurationDoc = {
    filename: string;
    url: string;
};

export type ChatConfigurationWithDocs = Prisma.ChatConfigurationGetPayload<{
    include: { presetMessages: true };
}> & { documents?: chatConfigurationDoc[]; hasPendingDocs?: boolean; clientPlan?: ClientPlan };

export interface ChatConfigurationService {
    getConfiguration(
        identifier: string,
        getDocs?: boolean,
        getPlan?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    postConfiguration(
        clientName: string,
        model: ModelVersion,
        documents: Blob[],
        deletedDocs?: string[],
        context?: string,
        organizationName?: string,
        businessDescription?: string,
        knowledgeBaseDescription?: string,
        botTaskDescription?: string,
        supportEmail?: string,
        botName?: string,
        statusText?: string,
        callToAction?: CTAConfiguration,
        inputPlaceholder?: string,
        answerTemperature?: number,
        questionTemperature?: number,
        showSourceDocs?: boolean,
        botIcon?: Blob,
        getDocs?: boolean,
        contactCenterConfiguration?: ContactCenterConfiguration,
        wantsProductsCardsInChat?: boolean,
        chatResetCommand?: string,
        autoOpenChat?: boolean,
        autoOpenDelay?: number,
        hasDataEnrichment?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    patchConfiguration(
        clientId: string,
        config: {
            model?: ModelVersion;
            context?: string;
            documents?: Blob[];
            deletedDocs?: string[];
            botName?: string;
            statusText?: string;
            callToAction?: CTAConfiguration;
            inputPlaceholder?: string;
            contactCenterDisabledText?: string;
            preOperatorMessage?: string;
            postOperatorMessage?: string;
            answerTemperature?: number;
            questionTemperature?: number;
            showSourceDocs?: boolean;
            botIcon?: Blob;
            getDocs?: boolean;
            showableAtPosition?: number;
            contactCenterEnabled?: boolean;
            chatResetCommand?: string;
        }
    ): Promise<BaseResponse<ChatConfigurationWithDocs>>;
    getFiles(clientId: string): Promise<BaseResponse<string[]>>;
}

export class ChatConfigurationImp extends BaseService implements ChatConfigurationService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Chat-Configuration-Service');
        this.httpRequest = httpRequest;
    }

    async getConfiguration(
        identifier: string,
        getDocs: boolean = false,
        getPlan: boolean = false
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        const request = () =>
            this.httpRequest.get<ChatConfigurationWithDocs>({
                url: generateUrl(`api/chat/config`, { identifier, docs: getDocs, plan: getPlan })
            });

        return this.tryRequest<ChatConfigurationWithDocs>(request);
    }

    async patchConfiguration(
        clientId: string,
        config: {
            model?: $Enums.ModelVersion;
            context?: string;
            documents?: Blob[];
            deletedDocs?: string[];
            botName?: string;
            statusText?: string;
            callToAction?: CTAConfiguration;
            inputPlaceholder?: string;
            contactCenterDisabledText?: string;
            preOperatorMessage?: string;
            postOperatorMessage?: string;
            answerTemperature?: number;
            questionTemperature?: number;
            showSourceDocs?: boolean;
            botIcon?: Blob;
            getDocs?: boolean;
            showableAtPosition?: number;
            contactCenterEnabled?: boolean;
            chatResetCommand?: string;
        }
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        const request = () =>
            this.httpRequest.patch<ChatConfigurationWithDocs>({
                url: generateUrl(`api/client/${clientId}/chat/config`),
                body: config
            });

        return this.tryRequest<ChatConfigurationWithDocs>(request);
    }

    async postConfiguration(
        clientName: string,
        model: ModelVersion,
        documents: Blob[],
        deletedDocs?: string[],
        context?: string,
        organizationName?: string,
        businessDescription?: string,
        knowledgeBaseDescription?: string,
        botTaskDescription?: string,
        supportEmail?: string,
        botName?: string,
        statusText?: string,
        callToAction?: CTAConfiguration,
        inputPlaceholder?: string,
        answerTemperature?: number,
        questionTemperature?: number,
        showSourceDocs?: boolean,
        botIcon?: Blob | null,
        getDocs: boolean = false,
        contactCenterConfiguration?: ContactCenterConfiguration,
        wantsProductsCardsInChat?: boolean,
        chatResetCommand?: string,
        autoOpenChat?: boolean,
        autoOpenChatDelay?: number,
        hasDataEnrichment?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithDocs>> {
        const form = new FormData();

        form.append('client', clientName);
        form.append('model', model);

        const appendIfDefined = (value: string | number | boolean | undefined, key: string) => {
            if (value !== undefined) form.append(key, value.toString());
        };

        appendIfDefined(callToAction?.enabled, 'callToActionEnabled');
        appendIfDefined(callToAction?.text, 'callToActionText');
        appendIfDefined(callToAction?.link, 'callToActionLink');
        appendIfDefined(context, 'context');
        appendIfDefined(organizationName, 'organizationName');
        appendIfDefined(businessDescription, 'businessDescription');
        appendIfDefined(knowledgeBaseDescription, 'knowledgeBaseDescription');
        appendIfDefined(botTaskDescription, 'botTaskDescription');
        appendIfDefined(supportEmail, 'supportEmail');
        appendIfDefined(botName, 'botName');
        appendIfDefined(statusText, 'statusText');
        appendIfDefined(inputPlaceholder, 'inputPlaceholder');
        appendIfDefined(wantsProductsCardsInChat, 'wantsProductsCardsInChat');
        appendIfDefined(hasDataEnrichment, 'hasDataEnrichment');
        appendIfDefined(showSourceDocs, 'showSourceDocs');
        appendIfDefined(contactCenterConfiguration?.enabled, 'contactCenterEnabled');
        appendIfDefined(contactCenterConfiguration?.humanCommand, 'humanCommand');
        appendIfDefined(contactCenterConfiguration?.disabledText, 'contactCenterDisabledText');
        appendIfDefined(contactCenterConfiguration?.preOperatorMessage, 'preOperatorMessage');
        appendIfDefined(contactCenterConfiguration?.postOperatorMessage, 'postOperatorMessage');
        appendIfDefined(chatResetCommand, 'chatResetCommand');
        appendIfDefined(autoOpenChat, 'autoOpenChat');

        if (
            typeof answerTemperature === 'number' &&
            answerTemperature >= 0 &&
            answerTemperature <= 1
        )
            form.append('answerTemperature', answerTemperature.toString());
        if (
            typeof questionTemperature === 'number' &&
            questionTemperature >= 0 &&
            questionTemperature <= 1
        )
            form.append('questionTemperature', questionTemperature.toString());

        if (Array.isArray(documents) && documents.length)
            documents.forEach((document: Blob, index: number) =>
                form.append(`document${index + 1}`, document)
            );

        if (Array.isArray(deletedDocs) && deletedDocs.length)
            form.append('deletedDocs', JSON.stringify(deletedDocs));

        if (botIcon !== undefined) form.append('botIcon', botIcon || 'null');

        if (
            autoOpenChat &&
            typeof autoOpenChatDelay === 'number' &&
            autoOpenChatDelay >= 1 &&
            autoOpenChatDelay <= 120
        )
            form.append('autoOpenChatDelay', autoOpenChatDelay.toString());

        return await this.tryRequest(async () =>
            this.httpRequest.post({
                url: generateUrl(`api/chat/setup`, { docs: getDocs }),
                body: form
            })
        );
    }

    async getFiles(clientId: string): Promise<BaseResponse<string[]>> {
        return this.tryRequest(async () =>
            this.httpRequest.get({
                url: generateUrl(`api/client/${clientId}/chat/config/files`)
            })
        );
    }
}
