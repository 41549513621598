import dynamic from 'next/dynamic';

// Import modals dynamically
export const dynamicModals = {
    commons: {
        alert: dynamic(() => import('./commons/alert'), {
            ssr: false
        }),
        confirmation: dynamic(() => import('./commons/confirmation'), {
            ssr: false
        }),
        delete: dynamic(() => import('./commons/delete'), {
            ssr: false
        })
    }
};
