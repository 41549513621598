import Image from 'next/image';
import React from 'react';

import { Name } from '../organisms/sidebar';

interface SidebarItemProps {
    name: Name;
    icon: string;
    isSelected: boolean;
    onClick: () => void;
}

const SidebarItem = ({ name, icon, isSelected, onClick }: SidebarItemProps) => {
    const iconName = isSelected ? icon : `${icon}_not_selected`;

    const handleClick = () => {
        !isSelected && onClick();
    };

    return (
        <ul>
            <li
                className={`relative text-black ${
                    isSelected ? 'bg-secondary' : 'hover:bg-gray-100 hover:cursor-pointer'
                }`}
                onClick={handleClick}
            >
                <div
                    className={`absolute top-0 left-0 bg-primary h-full ${
                        isSelected ? 'w-1.5' : 'w-0'
                    } transition-all duration-300`}
                />
                <div className="pl-12 py-6 flex items-center gap-x-6">
                    <Image height={24} width={24} src={`/icons/${iconName}.svg`} alt="item icon" />
                    <span className={`font-semibold	${isSelected ? 'text-primary' : ''}`}>
                        {name}
                    </span>
                </div>
            </li>
        </ul>
    );
};

export default SidebarItem;
